<template>
    <loading-animation v-if="DataLoading"></loading-animation>
    <div v-else class="ml-3 mr-3">
        <div class="fl-te-c mb-3">
            <h4>Purchase Order Details</h4>
            <btn size="sm" icon="fa fa-history" @click="$router.push('/purchase/order/' + id + '/history/')" text="History"></btn>
        </div>
        <div v-if="data" class="card bs-4 p-0 pb-3">
            <div class="fl-te-c mb-3 bb-1 p-3">
                <h5>Order Details</h5>
                <div class="btn-group">
                    <btn size="sm" class="btn-basic-b" @click="$router.push('/purchase/order/list/')" text="Back"></btn>
                    <btn size="sm" class="btn-basic-b" v-if="data.status==='PO Generated'" :loading="loading" loading-text="Approving..." @click="submitClicked"
                         text="Approve"></btn>
                </div>
            </div>
            <div class="row pl-3 pt-3 pr-5">
                <div class="col-2 label">Purchase Order ID</div>
                <div class="col-2 p-1 b-1 text-muted">{{ data.order_id }}</div>
                <div class="col-2"></div>
                <div class="col-2 label">Created by</div>
                <div class="col-2 p-1 b-1 text-muted">{{ data.created_by }} </div>
                <div class="col-2"></div>
            </div>
            <div class="row pl-3 pt-3 pr-5">
                <div class="col-2 label">Status</div>
                <div class="col-2 p-1 b-1 text-muted">{{ data.status }}</div>
                <div class="col-2"></div>
                <div class="col-2 label">Date</div>
                <div class="col-2 p-1 b-1 text-muted">{{ data.created_date }} </div>
                <div class="col-2"></div>
            </div>
            <custom-simple-table class="table-borderless mt-4 mb-0" v-if="data.items.length!==0" :data="data.items" :fields="fields" ref="table"/>
        </div>
    </div>
</template>

<script>

import axios from 'secure-axios';
import urls from '../../../../data/urls';

export default {
    name : 'DetailsPage',
    data () {
        return {
            DataLoading : false,
            loading     : false,
            id          : this.$route.params.id,
            data        : '',
            fields      : [
                {
                    name      : 'id',
                    sortField : 'id',
                    title     : 'ID'
                },
                {
                    name      : 'product',
                    sortField : 'product',
                    title     : 'Product'
                },
                {
                    name      : 'quantity',
                    sortField : 'quantity',
                    title     : 'Quantity'
                },
                {
                    name      : 'unit_price',
                    sortField : 'unit_price',
                    title     : 'Price'
                },
                {
                    name      : 'total',
                    sortField : 'total',
                    title     : 'Total'
                },
                {
                    name      : 'order_remarks',
                    sortField : 'order_remarks',
                    title     : 'Remarks'
                }

            ]
        };
    },
    mounted () {
        this.loadDetails();
    },
    methods : {
        async loadDetails () {
            const that = this;
            that.DataLoading = true;
            const response = await axios.form(urls.purchaseAdmin.purchaseOrder.details, { id : that.id });
            const json = response.data;
            if (json.error === false) {
                that.data = json.data;
                that.DataLoading = false;
            } else {
                that.$notify('Details not found.. Please try again later', 'Error', {
                    type : 'danger'
                });
                that.DataLoading = false;
            }
            that.DataLoading = false;
        },
        async submitClicked (rowData) {
            const that = this;
            that.loading = true;
            const response = await axios.form(urls.purchaseAdmin.purchaseOrder.submitOrder, {
                id : that.id
            });
            const json = response.data;
            if (json.error === false) {
                that.$notify('Order send for approval', 'Success', {
                    type : 'success'
                });
                that.$router.push('/purchase/order/list/');
                that.loading = false;
            } else {
                that.$notify('Unable to submit the order.. Please try again later', 'Error', {
                    type : 'danger'
                });
                that.loading = false;
            }
        }
    }
};
</script>

<style scoped>
.b-1 {
    border-radius: 4px;
}

.label {
    font-weight: 500;
}
</style>
